import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { taxApi } from "./apis/taxApi";

import { personalInfoApi } from "./apis/userInfoApi";
import {
  filesReducer,
  setAccountName,
  setBroker,
  uploadFreedomFiles,
  uploadInteractiveFiles,
} from "./slices/filesSlice";
import { modalReducer, setShowModal } from "./slices/modalSlice";
import { reportsReducer, setPay } from "./slices/reportsSlice";

const store = configureStore({
  reducer: {
    modal: modalReducer,
    file: filesReducer,
    reports: reportsReducer,
    [taxApi.reducerPath]: taxApi.reducer,
    [personalInfoApi.reducerPath]: personalInfoApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(taxApi.middleware, personalInfoApi.middleware);
  },
});

setupListeners(store.dispatch);

export {
  useBecomeReferredMutation, useCreateEncryptionKeyMutation, useDeleteAccountMutation, useDeleteFileMutation, useFetchMeQuery, useReferralInfoQuery, useSendEncryptedFileMutation, useSynchronizedFilesQuery, useSynchronizeFilesMutation, useSynchronizeFinalMutation, useSynchronizingFilesQuery, useTaxDeclarationsCalculatePreliminaryMutation, useTaxDeclarationsInvestingAccountsQuery, useTaxDeclarationsPaymentsMutation, useTaxDeclarationsYearQuery
} from "./apis/taxApi";

export {
  setAccountName, setBroker, setPay, setShowModal, store, taxApi, uploadFreedomFiles,
  uploadInteractiveFiles
};

