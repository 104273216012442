import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import ImageShow from "../components/ImageShow";
import { createKeys, decryptKeys } from "../helpers/forgeEncryption";
import useBroker from "../hooks/use-broker";
import useToken from "../hooks/use-token";
import {
  uploadFreedomFiles,
  uploadInteractiveFiles,
  useCreateEncryptionKeyMutation,
  useDeleteFileMutation,
  useSendEncryptedFileMutation,
} from "../store";
import File from "./File";

function DragDrop() {
  const dispatch = useDispatch();
  const [createEncryptionKey] = useCreateEncryptionKeyMutation();
  const [sendEncryptedFile] = useSendEncryptedFileMutation();
  const [deleteFile] = useDeleteFileMutation();
  const { broker } = useBroker();
  const token = useToken();
  let fileTypes = ["JSON"];
  let brokerType = "FF_CYPRUS_JSON";
  if (broker !== "freedom") {
    brokerType = "IBKR_ACTIVITY_STATEMENT_CSV";
    fileTypes = ["CSV"];
  }
  const [loading, setLoading] = useState({});

  const files = useSelector((state) => {
    if (broker === "freedom") {
      return state.file.freedomFiles;
    } else {
      return state.file.interactiveFiles;
    }
  });

  const handleChange = (file) => {
    document.querySelector(".error").innerHTML = "";
    let arr = [];
    for (let i = 0; i < file.length; i++) {
      setLoading({ ...loading, [file[i].name]: true });
      createKeys()
        .then((createdKeys) => {
          const { publicKey, privateKey } = createdKeys;
          createEncryptionKey({
            token,
            key: publicKey,
          })
            .unwrap()
            .then((encryptedKey) => {
              decryptKeys(encryptedKey, privateKey, file[i]).then(
                (decryptedKeys) => {
                  sendEncryptedFile({
                    token,
                    type: brokerType,
                    id: encryptedKey.id,
                    file: decryptedKeys.encryptedFile,
                    fileName: file[i].name,
                  }).then((results) => {
                    const data = {
                      file: file[i],
                      data: {
                        id: results.data?.id,
                        encryptedFile: decryptedKeys.encryptedFile,
                      },
                      error: results.error,
                    };
                    arr.push(data);

                    if (broker === "freedom") {
                      dispatch(uploadFreedomFiles([...files, ...arr]));
                    } else {
                      dispatch(uploadInteractiveFiles([...files, ...arr]));
                    }
                  });
                }
              );
            })
            .catch((err) => console.log(err));
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading({ ...loading, [file.name]: false });
        });
    }
  };

  const removeFile = (indexToRemove) => {
    const file = files[indexToRemove];
    if (!file.error) {
      deleteFile({
        token,
        id: file.data.id,
      });
    }

    const updatedFiles = files.filter((_, index) => {
      return index !== indexToRemove;
    });
    if (broker === "freedom") {
      dispatch(uploadFreedomFiles(updatedFiles));
    } else {
      dispatch(uploadInteractiveFiles(updatedFiles));
    }
  };

  const renderedFiles = files.map((file, index) => {
    return (
      <File
        key={index}
        file={file.file}
        index={index}
        deleteFile={removeFile}
        error={file.error}
        loading={loading[file.name]}
      />
    );
  });

  const content = (
    <div className="w-[485px] bg-zinc-50 rounded-2xl p-5 cursor-pointer hover:bg-gray-75">
      <div className="underline underline-offset-4 text-gray-350 text-base block">
        <ImageShow
          className="mr-2"
          image={{ url: "upload", label: "Freedom" }}
        />
        <span>Перетягніть файли або завантажте з компʼютера</span>
      </div>
      <div className="error text-red-600"></div>
    </div>
  );

  const onTypeError = (err) => {
    document.querySelector(
      ".error"
    ).innerHTML = `Невірний формат файлу. Файл має бути в форматі  ${fileTypes}`;
    console.log(err);
  };

  const fileError = files.find((file) => file.error);

  return (
    <div>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        multiple
        classes="drop-area"
        children={content}
        onTypeError={(err) => onTypeError(err)}
      />
      <div
        className={
          renderedFiles?.length > 0
            ? "w-[485px] bg-zinc-50 rounded-2xl p-5"
            : ""
        }>
        {renderedFiles}
        {
          (fileError && files.length > 0) && (
            <div className="rounded-2xl text-xs bg-gradient-to-r from-[#D3CB03] to-[#FFD600] flex justify-center py-2 mt-3 mb-[-8px]">
              видаліть, або замініть файл з помилкою
            </div>
          )
        }
      </div>
    </div>
  );
}

export default DragDrop;
