import clsx from "clsx";
import ImageShow from "../components/ImageShow";
import Link from "../components/Link";

function formatFileSize(size) {
  if (size < 1024) {
    return `${size} B`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
}

function File({ file, index, deleteFile, error, loading }) {
  // <GoSync className="animate-spin inline-block text-green-700" />
  const icon = loading ? (
    <div className={clsx('flex items-center', "mr-3")}>
      <ImageShow className="mr-0 rotate" image={{ url: "loading", label: "Loading" }} />
    </div>
  ) : error ? (
    <ImageShow className="mr-3" image={{ url: "error", label: "Error" }} />
  ) : 
  (
    <ImageShow className="mr-3" image={{ url: "checked", label: "Checked" }} />
  );

  return (
    <div className="flex flex-row rounded-2xl bg-white p-5 mb-1">
      {icon}
      <div className="flex flex-col w-full">
        <p className="text-sm break-all">{file?.name}</p>
        <p className="text-xs text-gray-350">{formatFileSize(file?.size)}</p>
      </div>
      <div className="w-[200px] self-center flex justify-end relative cursor-pointer">
        {error && (
          <>
            <Link
              className="text-sm h-[25px] text-gray-350 underline underline-offset-4"
              handleClick={(e) => e.preventDefault}>
              1 Помилка
              <div className="relative -top-5 h-[25px] justify-center opacity-0 hover:opacity-100 w-[85px]">
                <p className="absolute -top-10 text-sm border-white shadow-md min-w-[184px] ml-20 rounded-xl bg-white p-4 pointer-events-none">
                  {error?.data?.commonMessage}
                </p>
              </div>
            </Link>
          </>
        )}
        <button
          className="place-self-end self-center"
          onClick={() => deleteFile(index)}>
          <ImageShow image={{ url: "remove", label: "Remove" }} />
        </button>
      </div>
    </div>
  );
}

export default File;
