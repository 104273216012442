import { useState } from "react";
import Button from "../components/Button";
import Dropdown from "../components/Dropdown";
import ImageShow from "../components/ImageShow";
import Link from "../components/Link";
import Reports from "../components/Reports";
import { FREEDOM_TYPE, INTERACTIVE_TYPE } from "../constants/constants";
import useToken from "../hooks/use-token";
import { useSynchronizedFilesQuery } from "../store";

function ReportsPage() {
  const token = useToken();
  const [dataProvider, setDataProvider] = useState("");
  let params = {
    token,
    dataProvider: dataProvider,
    page: 0,
    size: 10,
  };
  const { data, error, isFetching, refetch } =
    useSynchronizedFilesQuery(params);

  const [selection, setSelection] = useState(null);

  const handleSelect = (option) => {
    setSelection(option);
    if (option.value === "freedom") {
      setDataProvider(FREEDOM_TYPE);
      params.dataProvider = dataProvider;
    } else if (option.value === "interactive") {
      setDataProvider(INTERACTIVE_TYPE);
      params.dataProvider = dataProvider;
    } else {
      setDataProvider("");
      params.dataProvider = dataProvider;
    }
    refetch(params);
  };

  const options = [
    { label: "Брокер", value: "broker" },
    { label: "Freedom Broker", value: "freedom" },
    { label: "InteractiveBrokers", value: "interactive" },
  ];

  const content = <Reports data={data?.content} />;

  return (
    <div className="page flex flex-col">
      <h1 className="text-black text-lg sm:text-2xl sm:pb-3">Рахунки</h1>
      <div className="py-5 flex sm:mb-20">
        <form className="relative">
          <input
            className="pl-8 rounded-md text-sm text-gray-350 border border-gray-150 w-40 h-10 focus-visible:outline-cyan-450"
            type="text"
          />
          <Button className="absolute top-[10px] left-2">
            <ImageShow
              className=""
              image={{ url: "search", label: "Search" }}
            />
          </Button>
        </form>
        <div className="flex ml-2.5">
          <Dropdown options={options} value={selection} onChange={handleSelect}>
            <span className="text-sm text-gray-350 pr-2">Брокер</span>
          </Dropdown>
        </div>
        <Link
          to="/accounts"
          className="hidden sm:flex ml-auto h-[37px] items-center justify-center border-transparent rounded-xl text-white bg-gradient-to-r from-cyan-650 to-cyan-75 hover:from-cyan-900 hover:to-cyan-650 px-3 text-sm">
          + Імпортувати звіти
        </Link>
      </div>
      <div className="[&>*:not(:first-child)]:border-t [&>*:last-child]:border-b">
        {content && content}
      </div>
      <div className="mt-6 flex items-center justify-between">
        <div className="text-xs text-gray-350 justify-self-start">
          {data && data?.size + "/" + data?.totalElements}
        </div>
        <div className="flex text-gray-350 mx-auto">
          <Link
            className="pointer-events-none"
            to="/"
            onClick={(e) => e.preventDefault()}>
            <ImageShow
              className=""
              image={{ url: "arrow-left", label: "Arrow Left" }}
            />
          </Link>
          <Link className="pt-px" to="/" onClick={(e) => e.preventDefault()}>
            1
          </Link>
          <Link
            className="pointer-events-none"
            to="/"
            onClick={(e) => e.preventDefault()}>
            <ImageShow
              className="ml-2"
              image={{ url: "arrow-right", label: "Arrow Right" }}
            />
          </Link>
        </div>
      </div>
      <div className="flex mt-4">
        <Link 
          to="/taxreports"
          className="sm:flex h-14 w-52 items-center justify-center border-transparent rounded-xl text-white bg-gradient-to-r from-cyan-650 to-cyan-75 hover:from-cyan-900 hover:to-cyan-650 px-3 text-sm"
        >
          Провести розрахунок
        </Link>
      </div>
    </div>
  );
}

export default ReportsPage;
