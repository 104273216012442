import { useEffect, useState } from "react";
import { GoSync } from "react-icons/go";
import Dropdown from "../../components/Dropdown";
import Link from "../../components/Link";
import useToken from "../../hooks/use-token";
import useYearOptions from "../../hooks/use-year-options";

import { useDispatch, useSelector } from "react-redux";
import Checkout from "../../components/Checkout";
import ImageShow from "../../components/ImageShow";
import InvestAccountContent from "../../components/InvestAccountContent";
import Modal from "../../components/Modal";
import Reports from "../../components/Reports";
import { setPay, setShowModal } from "../../store";
import { useLazyTaxDeclarationsInvestingAccountsQuery, useTaxDeclarationsYearQuery } from "../../store/apis/taxApi";
import { YearSection } from "./YearSection";

function InfoPage() {
  const dispatch = useDispatch();

  const token = useToken();

	const url = new URL(window.location.href);

  const paidParam = url.searchParams.get("paid");
  const yearParam = url.searchParams.get("year");

  let { year, options } = useYearOptions();
	const [selection, setSelection] = useState({ label: yearParam || year, value: yearParam || year });

  const {
    data,
    error: errorTax,
    isFetching: isFetchingTax,
  } = useTaxDeclarationsYearQuery({ year: selection.value, token });
  const [
    triggerTaxDeclarationsInvestingAccounts, 
    { 
      error: errorInvestAccount, 
      data: dataInvestAccount, 
      isLoading: isFetchingInvestAccount 
    }
  ] = useLazyTaxDeclarationsInvestingAccountsQuery();

	const handleSelect = (option) => {
    setSelection(option);
  };

  const handleClose = () => {
    dispatch(setShowModal(false));
  };

  const allowCalculation =
    dataInvestAccount?.investingAccountForTaxDeclarations?.find((x) => x.hasPhantomOperations);

  const showModal = useSelector((state) => {
    return state.modal.showModal;
  });
  const paid = useSelector((state) => {
    return state.reports.paid;
  });

  useEffect(() => {
    if (!isFetchingTax && (errorTax || !data.yearData.data)) {
      triggerTaxDeclarationsInvestingAccounts({ year: selection.value, token });
    }
  }, [errorTax, isFetchingTax, data, triggerTaxDeclarationsInvestingAccounts, selection.value, token]);

  useEffect(() => {
    if (paid) {
      setTimeout(() => {
        dispatch(setPay(false));
        window.history.pushState({}, document.title, window.location.pathname);
      }, 4000);
    }
  }, [paid]);

  useEffect(() => {
    if (paidParam) {
      dispatch(setPay(true));
    }
  }, [paidParam, dispatch]);
    
	return (
    <>
      <div className="page">
        <h1 className="text-black inline-block text-lg leading-10 sm:leading-normal sm:text-2xl sm:pb-1">
          Податкові звіти
        </h1>
        <div className="w-full sm:w-auto flex items-end flex-wrap sm:flex-nowrap mt-5 sm:mt-0">
          <p className="mr-2 text-gray-350 w-full sm:w-auto">
            Оберіть податковий період:
          </p>
          <Dropdown
            options={options}
            value={selection}
            onChange={handleSelect}
            noborder
            className="mt-1 sm:mt-0 text-2xl leading-7 bg-clip-text text-transparent bg-gradient-to-r from-cyan-650 to-cyan-75">
            <span>{year}</span>
          </Dropdown>
        </div>
        {((errorTax || !data?.yearData?.data) && (errorInvestAccount || !dataInvestAccount) && !isFetchingTax && !isFetchingInvestAccount) && (
          <div
            className="w-full items-center py-4 mt-7 sm:mt-16 min-h-[80px] 
          flex justify-left px-5 sm:px-7 border-transparent rounded-xl text-white 
          bg-gradient-to-r from-cyan-650 to-cyan-75">
            <p className="text-sm sm:text-normal">
              За цей рік Ви ще не імпортували жодного звіту. Для імпорту
              перейдіть на сторінку{" "}
              <Link to="/reports" className="underline">
                “Рахунки”
              </Link>
            </p>
          </div>
        )}
        <div className="mt-7">
          {
            isFetchingTax || isFetchingInvestAccount 
            ? <GoSync className="animate-spin inline-block text-green-700" />
            : !errorTax && data?.yearData?.data 
              ? <YearSection data={data} />
              : dataInvestAccount && !errorInvestAccount
                ? (
                  <InvestAccountContent
                    content={
                      <Reports
                        data={dataInvestAccount?.investingAccountForTaxDeclarations}
                        taxPage
                      />
                    }
                    allowCalculation={allowCalculation}
                  /> 
                ) : null
          }
        </div>
      </div>
      {paid &&  
        <Modal
          onClose={handleClose}
          actionBar={
            <>
              <ImageShow
                image={{ url: "checked-big-white", label: "checked big white" }}
              />
              <p className="mt-3 text-white">Оплата прошла успішно</p>
            </>
          }
          paid
        />
      }
      {showModal &&  
        <Modal
          onClose={handleClose}
          actionBar={<Checkout year={selection.value} />}
          checkout
        />
      }
    </>
  );
}

export default InfoPage;