import { useAuth } from 'react-oidc-context';
import Link from '../components/Link';
import Sidebar from '../components/Sidebar';
import { ACTIVE_CLASS } from '../constants/constants';
import ActiveLink from './ActiveLink';

function LeftBar() {
  const auth = useAuth();

  const handleLogout = () => {
    auth.removeUser();
    auth.signoutRedirect();
  };
  return (
    <div className="hidden lg:flex bg-zinc-50 relative h-full bg-gray-25 flex-col lg:gap-4 w-[230px]"> 
      <div className="font-roboto font-medium text-2xl pl-8 pt-7 bg-clip-text text-transparent bg-gradient-to-r from-cyan-650 to-cyan-75">
        OSA.tax
      </div>
      {/* <div className="text-xs text-gray-350 ml-8 mt-3 w-[148px] rounded  p-2 bg-white">
        <p>
          Вітаємо вас в <br />
          особистому кабінеті
        </p>
      </div> */}

      <Sidebar />
      <div className="flex flex-1 items-end">
        <div className="py-6 flex-1">
          {/* <Link
            icon="setting"
            className="text-gray-350 text-sm block group pl-8 py-3"
            activeClassName={ACTIVE_CLASS}>
            Налаштування
            <ActiveLink />
          </Link> */}
          <div className="mt-6 mb-4 text-gray-350 text-sm inline-block flex items-center pl-4">
            <span
              className="flex justify-center items-center
                          font-medium rounded-full bg-gradient-to-r from-cyan-650 to-cyan-75 w-[30px] 
                          h-[30px] text-white"
            >
              {auth.user?.profile.given_name.slice(0, 1)}
            </span>
            <p className="ml-2 leading-4 text-black font-medium">
              {auth.user?.profile.given_name} 
              <br />
              {auth.user?.profile.family_name}
            </p>
          </div>
          <Link
            to="/cabinet"
            className="lg:mx-0 flex justify-center lg:justify-normal lg:flex-none lg:h-auto h-[60px] lg:pb-3 text-sm lg:w-auto lg:pl-7 lg:py-3 text-gray-350 group lg:pr-6 whitespace-nowrap"
            activeClassName={ACTIVE_CLASS}
            icon='profile'
            iconSize={20}
            iconClass="lg:mr-2 mr-0 w-[20px] h-[20px]"
          >
            <span className="hidden lg:inline-block">
              Мій кабінет
            </span>
            <ActiveLink />
          </Link>
          {/* <Link
            icon="profile"
            to="/profile"
            className="text-gray-350 text-sm pl-6 py-3 block hover:cursor-pointer whitespace-nowrap"
          >
            Мій кабінет
          </Link> */}
          <Link
            icon="logout"
            handleClick={handleLogout}
            className="lg:mx-0 flex justify-center lg:justify-normal lg:flex-none lg:h-auto h-[60px] lg:pb-3 text-sm lg:w-auto lg:pl-7 lg:py-3 text-gray-350 group lg:pr-6 whitespace-nowrap">
            Вийти з аккаунту
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LeftBar;
