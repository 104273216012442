import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import Header from './components/Header';
import LeftBar from './components/LeftBar';
import { Loader } from './components/Loader/Loader';
import Route from './components/Route';
import Sidebar from './components/Sidebar';
import {
  ENABLE_INVESTING_ACCOUNT_CONTROLLER,
  ENABLE_PROFILE,
  ENABLE_REFERRAL_CONTROLLER,
  ENABLE_TAX_DECLARATION_CONTROLLER,
} from './constants/constants';
import useNavigation from './hooks/use-navigation';
import useToken from './hooks/use-token';
import AccountsPage from './pages/AccountsPage';
import { CabinetPage } from './pages/CabinetPage';
// import DeclarationPage from './pages/DeclarationPage';
import { ErrorBoundaryWrapper } from './components/ErrorBounder';
import HomePage from './pages/HomePage';
import IncomesPage from './pages/IncomesPage';
import ProfilePage from './pages/ProfilePage';
import ReferralInfoPage from './pages/ReferralInfoPage';
import ReferralPage from './pages/ReferralPage';
import ReportsPage from './pages/ReportsPage';
import SettingsPage from './pages/SettingsPage';
import TaxReportsPage from './pages/taxreports/TaxReportsPage';
import UploadPage from './pages/UploadPage';
import { useBecomeReferredMutation } from './store';

function App() {
  const auth = useAuth();
  const token = useToken();
  const [becomeReferred] = useBecomeReferredMutation();
  const url = new URL(window.location.href);
  const referral = url.searchParams.get('referral');
  const referralCode = JSON.parse(localStorage.getItem('referral'));
  if (referral && !referralCode) {
    localStorage.setItem('referral', JSON.stringify(referral));
  }

  const { currentPath } = useNavigation();

  useEffect(() => {
    if (referralCode && token) {
      becomeReferred({ token, referralCode }).then(() => {
        localStorage.removeItem('referral');
        window.location = '/';
      });
    }
  }, [becomeReferred, token, referralCode]);

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div></div>; // Входимо...
    case 'signoutRedirect':
      return <div></div>; // Виходимо...
    default:
    // do nothing
  }

  if (auth.isLoading) {
    return <Loader />; // Завантажується...
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    // http://localhost:3000?referral=dd943074-5410-4264-b2ec-64646fee6d18
    // console.log("referral ", referral, auth.user?.profile?.sub);
    return (
      <ErrorBoundaryWrapper>
        <div className="lg:mx-auto flex lg:min-h-[520px] overflow-hidden h-screen">
          <LeftBar />
          <div className={clsx(
            "w-full min-h-screen grid grid-rows-[auto_1fr_auto] h-screen overflow-auto",
            currentPath === '/' && 'bg-gradient-to-r from-cyan-650 to-cyan-75'
          )}>
            <div className="block lg:hidden z-[1]">
              <Header />
            </div>
            <Route path="/">
              <HomePage />
            </Route>
            {ENABLE_INVESTING_ACCOUNT_CONTROLLER && (
              <>
                <Route path="/accounts">
                  <AccountsPage />
                </Route>
                <Route path="/upload">
                  <UploadPage />
                </Route>
                <Route path="/reports">
                  <ReportsPage />
                </Route>
              </>
            )}
            {ENABLE_TAX_DECLARATION_CONTROLLER && (
              <>
                {/* <Route path="/declaration">
                  <DeclarationPage />
                </Route> */}
                <Route path="/taxreports">
                  <TaxReportsPage />
                </Route>
              </>
            )}
            {ENABLE_REFERRAL_CONTROLLER && (
              <>
                <Route path="/referral">
                  <ReferralPage />
                </Route>
                <Route path="/referral-info">
                  <ReferralInfoPage />
                </Route>
              </>
            )}
            {
              ENABLE_PROFILE && (
                <>
                  <Route path="/cabinet">
                    <CabinetPage />
                  </Route>
                  <Route path="/profile">
                    <ProfilePage />
                  </Route>
                  <Route path="/incomes">
                    <IncomesPage />
                  </Route>
                </>
              )
            }
            <Route path="/settings">
              <SettingsPage />
            </Route>
            <div className="block lg:hidden">
              <Sidebar />
            </div>
          </div>
        </div>
      </ErrorBoundaryWrapper>
    );
  }

  return void auth.signinRedirect();
}

export default App;
