import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageShow from "../components/ImageShow";
import useBroker from "../hooks/use-broker";
import useDate from "../hooks/use-date";
import useToken from "../hooks/use-token";
import {
  setAccountName,
  useDeleteAccountMutation,
  useDeleteFileMutation,
} from "../store";
import Button from "./Button";

function UploadedAccount({ account }) {
  const dispatch = useDispatch();
  const [deleteAccount] = useDeleteAccountMutation();
  const [deleteFile] = useDeleteFileMutation();
  const token = useToken();
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const { broker } = useBroker(account.provierType);
  const { getDate } = useDate();

  const accountName = useSelector((state) => {
    return state.file.accountName;
  });

  const changeTitle = (id, name) => {
    if (accountName.length > 0) {
      const found = accountName.find((item) => item.id === id);

      if (found) {
        const updatedItems = accountName.map((item) => {
          if (item.id === id) {
            return { id, name };
          }
          return item;
        });
        dispatch(setAccountName(updatedItems));
      } else {
        dispatch(setAccountName([...accountName, { id, name }]));
      }
    } else {
      dispatch(setAccountName([...accountName, { id, name }]));
    }
  };

  const handleChange = (event) => {
    const name = event.target.value;
    setTitle(name);
  };

  const editTitle = (event) => {
    event.preventDefault();
    setEdit(!edit);
  };

  const submitTitle = (id, event) => {
    event.preventDefault();
    const name = event.target[0].value;
    changeTitle(id, name);
    setEdit(false);
  };

  const removeAccount = (id) => {
    deleteAccount({
      token,
      id: id,
    });
  };

  const removeFile = (id) => {
    deleteFile({
      token,
      id: id,
    });
  };

  return (
    <div className="w-full pb-8">
      <div className="flex bg-gray-75 h-[90px] flex items-center rounded-t-2xl">
        <div className="py-5 pl-5">
          <ImageShow image={{ url: broker, label: broker }} />
        </div>
        <div className="py-5 flex flex-wrap">
          {!edit && (
            <>
              <p className="inline-block">{title ? title : account.name}</p>

              <Button onClick={editTitle} className="">
                <ImageShow
                  className="ml-2"
                  image={{ url: "edit", label: "Edit" }}
                />
              </Button>
            </>
          )}
          {edit && (
            <form
              onSubmit={(event) => submitTitle(account.id, event)}
              className="flex">
              <input
                className="px-2 py-1 rounded-md text-gray-350 focus-visible:outline-cyan-450"
                type="text"
                onChange={handleChange}
                value={title ? title : account.name}
                accountId={account.id}
              />
              <Button className="ml-3 px-3 bg-gray-450 rounded-md">
                Зберегти
              </Button>
            </form>
          )}
          <p className="text-sm text-gray-350 basis-full">
            Номер рахунку: {account.originalId}
          </p>
        </div>
        <Button
          disabled
          className="place-self-end self-center mr-6 ml-auto"
          onClick={() => removeAccount(account.id)}>
          <ImageShow image={{ url: "remove", label: "Remove" }} />
        </Button>
      </div>
      {account.brokerReports.map((file, index) => {
        const periodStart = getDate(file.periodStart);

        const periodEnd = getDate(file.periodEnd);
        return (
          <div
            key={file.id}
            className="flex items-center h-[75px] bg-gray-25 border-b border-gray-75">
            <div className="flex justify-center text-[28px] w-[75px] text-gray-350">
              {index + 1}
            </div>
            <div className="">
              <p className="text-sm">{file.fileName}</p>
              <span className="text-xs text-gray-350">
                {file.fileSize / 1000} KB
              </span>
            </div>
            <div className="mr-8 text-sm ml-auto">
              <p className="text-sm">Операцій</p>
              <span className="text-xs text-gray-350">
                {file.transactionCount}
              </span>
            </div>
            <div className="mr-8">
              <p className="text-sm">Період звіту</p>
              <span className="text-xs text-gray-350">
                {periodStart} – {periodEnd}
              </span>
            </div>
            <button
              className="place-self-end self-center mr-6"
              onClick={() => removeFile(file.id)}>
              <ImageShow image={{ url: "remove", label: "Remove" }} />
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default UploadedAccount;
