import clsx from "clsx";
import { useState } from "react";

export function Tooltip({ children, content, type, className }) {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
		<div
			className={clsx("relative inline-block cursor-pointer", className)}
			onMouseEnter={showTooltip}
			onMouseLeave={hideTooltip}
			// onClick={showTooltip}
		>
			{
				children 
				? children : (
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="0.5" y="0.5" width="11" height="11" rx="5.5" stroke="#929292"/>
						<path d="M5.53103 7.232C5.53103 7.024 5.5657 6.84267 5.63503 6.688C5.70436 6.53333 5.7897 6.39467 5.89103 6.272C5.9977 6.14933 6.11236 6.03733 6.23503 5.936C6.3577 5.82933 6.47236 5.72533 6.57903 5.624C6.6857 5.51733 6.77103 5.40533 6.83503 5.288C6.90436 5.17067 6.93903 5.03467 6.93903 4.88C6.93903 4.624 6.8377 4.416 6.63503 4.256C6.43236 4.096 6.15236 4.016 5.79503 4.016C5.47503 4.016 5.1977 4.06933 4.96303 4.176C4.72836 4.28267 4.52836 4.44 4.36303 4.648L3.77903 4.232C4.00303 3.94933 4.28836 3.73067 4.63503 3.576C4.9817 3.416 5.38703 3.336 5.85103 3.336C6.23503 3.336 6.56836 3.39467 6.85103 3.512C7.1337 3.624 7.35236 3.78667 7.50703 4C7.66703 4.208 7.74703 4.46133 7.74703 4.76C7.74703 4.984 7.71236 5.176 7.64303 5.336C7.5737 5.496 7.4857 5.63733 7.37903 5.76C7.27236 5.88267 7.1577 5.99733 7.03503 6.104C6.9177 6.21067 6.8057 6.31733 6.69903 6.424C6.59236 6.53067 6.50436 6.648 6.43503 6.776C6.3657 6.904 6.33103 7.056 6.33103 7.232H5.53103ZM5.93903 9.048C5.7897 9.048 5.66436 8.99733 5.56303 8.896C5.46703 8.79467 5.41903 8.67467 5.41903 8.536C5.41903 8.38667 5.46703 8.264 5.56303 8.168C5.66436 8.072 5.7897 8.024 5.93903 8.024C6.08836 8.024 6.21103 8.072 6.30703 8.168C6.40303 8.264 6.45103 8.38667 6.45103 8.536C6.45103 8.67467 6.40303 8.79467 6.30703 8.896C6.21103 8.99733 6.08836 9.048 5.93903 9.048Z" fill="#929292"/>
					</svg>
				)
			}
			{visible && (
				<div 
					className={
						clsx("absolute bottom-full right-0 transform -translate-x-0 bg-white text-black text-sm p-2 rounded-2xl z-10 transition-opacity duration-200 tooltip-content w-96"
					)}
				>
					{content}
				</div>
			)}
		</div>
  );
}
