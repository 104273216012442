import React from 'react';
import ImageShow from '../components/ImageShow';
// import Chart from "chart.js/auto";
// import { Line } from "react-chartjs-2";

function Home() {
  // const data = {
  //   labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  //   datasets: [
  //     {
  //       label: 'Freedom Broker',
  //       data: [33, 53, 85, 41, 44, 65],
  //       fill: true,
  //       backgroundColor: 'rgba(147,180,191,0.2)',
  //       borderColor: '#1A677B',
  //       tension: 0.5,
  //       pointStyle: 'circle',
  //       pointRadius: 6,
  //       pointHoverRadius: 10,
  //     },
  //     {
  //       label: 'InteractiveBrokers',
  //       data: [33, 25, 35, 51, 54, 76],
  //       fill: false,
  //       borderColor: '#742774',
  //       tension: 0.5,
  //       pointStyle: 'circle',
  //       pointRadius: 6,
  //       pointHoverRadius: 10,
  //     },
  //   ],
  // };
  return (
    <div className="page mt-[-16px] xl:mt-0 text-white relative bg-gradient-to-r from-cyan-650 to-cyan-75 z-[0]">
      <h1 className="text-2xl xl:pb-14 xl:pb-8 sm:pb-4">Головна</h1>
      <h2 className="text-[32px] xl:pb-8 xl:pb-4 sm:pb-2">
        Вітаю на платформі з підготовки податкових декларацій для інвесторів
      </h2>
      <div className='flex flex-col w-full xl:gap-2 overflow-hidden xl:flex-row sm:gap-1'> 
        <div className='flex-1 min-w-[40%] flex flex-wrap gap-2 flex-row'>
          <div className="p-7 flex flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50 flex-1 min-w-[40%]">
            <h3 className="text-[18px] text-cyan-650 mb-8">РАХУНКИ</h3>
            <p className="text-sm text-gray-350">
              Розділ для завантаження брокерських звітів, для подальшого розрахунку
            </p>
          </div>
          <div className="p-7 flex flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50 flex-1 min-w-[40%]">
            <h3 className="text-[18px] text-cyan-650 mb-8">ПОДАТКОВІ ЗВІТИ </h3>
            <p className="text-sm text-gray-350">
              Для формування та завантаження податкових декларацій
            </p>
          </div>
          <div className="p-7 flex flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50 flex-1 min-w-[40%]">
            <h3 className="text-[18px] text-cyan-650 mb-8">ПОДАТКОВІ ДРУЗІ</h3>
            <p className="text-sm text-gray-350">
              Реферальна програма де ви можете запросити своїх друзів і отримати за це бонуси
            </p>
          </div>
          <div className="p-7 flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50 flex-1 min-w-[40%]">
            <div className="flex justify-between">
              <h3 className="text-[18px] text-cyan-650 mb-8">OSA-WIKI</h3>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://osa-tax.notion.site/WIKI-731de380af7e415d91bf9b6bd89c33b5?pvs=4"
                className="-mt-[5px] flex justify-center items-center w-[110px] h-[36px] mb-5 border-transparent rounded-xl text-white bg-gradient-to-r from-cyan-650 to-cyan-75 hover:from-cyan-900 hover:to-cyan-650">
                Відкрити
              </a>
            </div>
            <p className="text-sm text-gray-350">
              Наша “База Знань” де ви можете знайти відповіді на найпоширеніші питання
            </p>
          </div>
        </div>
        <div className='flex-1'>
          <div className="flex-col rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50 flex-1 h-full flex h-[45vh] max-h-[45vh]">
            <div className="flex justify-between p-7 pb-0">
              <h3 className="text-[18px] text-cyan-650 mb-4"> ЯК ПІДГОТУВАТИ ДЕКЛАРАЦІЮ НА ПЛАТФОРМІ </h3>
            </div>
            <div className='rounded-[30px] w-full overflow-hidden flex-1'> 
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/y0sF5xhGreA"
                title="Як підготувати декларацію на платформі"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
      <p className="text-[16px] xl:mt-12 sm:mt-6">Інші послуги:</p>

      <div className="flex flex-col xl:gap-7 sm:gap-4">
        <div className="flex text-[14px] mt-4 flex-1 xl:gap-16 sm:gap-4 sm:flex-col xl:flex-row">
          <div className='flex-[3] min-w-[200px] flex flex-col gap-2'>
            <div className="font-medium text-[18px] ">
              Консультація з питань оподаткування
            </div>
            <span>
              – зідзвон у зручному для вас форматі (дзвінок, Zoom, Google Meet)
            </span>
          </div>
          <div className="flex-[5]">
            Ви отримуєте відповіді на ваші питання, пов'язані з подачею декларації, подвійному оподаткуванню, методикою розрахунку, нюансами з оподаткування та нюансами, які можуть виникнути з податковою і т.д.
          </div>
          <div className="font-medium  flex-[2] text-[18px] mr-14">
            100$/год
          </div>
        </div>

        <div className="flex flex-1 border-b border-white" /> 

        <div className="flex text-[14px] mt-4 flex-1 xl:gap-16 sm:gap-4 sm:flex-col xl:flex-row">
          <div className='flex-[3] min-w-[200px] flex flex-col gap-2'>
            <div className="font-medium text-[18px] ">
              Розрахунок декларації
            </div>
          </div>
          <div className="flex-[5]">
            Ви отримуєте файли для завантаження у податковий кабінет, інструкцію по заповненню та подачі.
            <br /><br />
            Оплата за транзакції: за кожну угоду купівлі-продажу протягом року.
            <br /><br />
            Дублі угод ми не рахуємо (коли брокер розбиває одну угоду на декілька операцій), обмін валют не враховуємо, пасивні нарахування на рахунок ми так само не рахуємо (дивіденди, нарахування на залишок коштів, корпоративні дії)
          </div>
          <div className="font-medium  flex-[2] text-[18px]  mr-14">
           200$ + 0,35$ за транзакції
          </div>
        </div>

        <div className="flex flex-1 border-b border-white" /> 

        <div className="flex text-[14px] mt-4 flex-1 xl:gap-16 sm:gap-4 sm:flex-col xl:flex-row">
          <div className='flex-[3] min-w-[200px] flex flex-col gap-2'>
            <div className="font-medium text-[18px] ">
              Подача декларації
            </div>
          </div>
          <div className="flex-[5]">
            Ви надаєте нам ключі для входу у ваш електронний кабінет і ми самі заповнюємо усі дані за вас і подаємо, жодного кліка від вас.
          </div>
          <div className="font-medium  flex-[2] text-[18px] mr-14">
            25$
          </div>
        </div>

        <div className="flex flex-1 border-b border-white" /> 

        <div className="flex text-[14px] mt-4 flex-1 xl:gap-16 sm:gap-4 sm:flex-col xl:flex-row">
          <div className='flex-[3] min-w-[200px] flex flex-col gap-2'>
            <div className="font-medium text-[18px] ">
              Підготовка і надання відповіді на запит податкової
            </div>
          </div>
          <div className="flex-[5]">
          Готуємо і надаємо відповіді на запити податкової
          </div>
          <div className="font-medium  flex-[2] text-[18px]  mr-14">
            50$
          </div>
        </div>

        <div className="flex flex-1 border-b border-white" /> 

        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.google.com/forms/d/e/1FAIpQLSf_08On8Dz36N2unPltGAJTGVm-C5WGyePd6-0yzb_nKwKozQ/viewform"
          className="flex text-[14px] mt-4 text-cyan-650 justify-center items-center w-[202px] h-[36px] mb-5 border-transparent rounded-xl bg-white hover:bg-cyan-650 hover:text-white hover:border-white hover:border">
          Скористатися послугами
        </a>

        <div className='flex justify-between gap-2 mb-12 md:mb-4 items-end mt-[-32px]'> 
          <p className="text-[14px] flex sm:flex-col lgdef:flex-row lgdef:gap-5 gap-2 lgdef:mt-0 mt-4">
            <span className="block md:inline-block">
              Контакти для зв’язку з підтримкою:
            </span>
            <a className="" href="mailto:client.service@osa.tax">
              <ImageShow image={{ url: 'envelope', label: 'envelope' }} />
              client.service@osa.tax
            </a>
            <a className="" target="_blank" href="https://t.me/OSAtax">
              <ImageShow image={{ url: 'paperPlane', label: 'paperPlane' }} />
              @OSAtax
            </a>
          </p>

          <div className='flex justify-end gap-2 cursor-pointer' onClick={
            () => {
              window.open('https://osa-tax.notion.site/WIKI-731de380af7e415d91bf9b6bd89c33b5?pvs=4', '_blank');
            }
          }>
            <span className='text-white text-base flex flex-col items-end text-end'>
              WIKI
              <span className='text-sm'>
                Відповіді
                <br />
                на питання
              </span>
            </span>
            <div className='bg-white rounded-full text-[#93B4BF] w-14 h-14 justify-center items-center flex'>
              ?
            </div>
          </div>
        </div>
      </div>

      {/* <div className="p-4 mt-2 w-full h-[400px] rounded-[30px] bg-gradient-to-r from-gray-250 to-gray-50">
        <Line
          data={data}
          width={"100%"}
          options={{ maintainAspectRatio: false }}
        />
      </div> */}
    </div>
  );
}

export default Home;
