import React from "react";

export function ErrorFallback() {
	return (
		<div>
			<h1>Something went wrong</h1>
		</div>
	);
}

export class ErrorBoundaryWrapper extends React.Component {
	state = { hasError: false };

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		
	}

	render() {
		if (this.state.hasError) {
			return <ErrorFallback />;
		}

		return this.props.children;
	}
}