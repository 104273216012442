import { Checkbox, Typography } from "@material-tailwind/react";
import { useCallback, useEffect, useState } from "react";
import Fondy from "../components/Fondy";
import useToken from "../hooks/use-token";
import {
  useTaxDeclarationsCalculatePreliminaryMutation,
  useTaxDeclarationsPaymentsMutation,
} from "../store";
import ImageShow from "./ImageShow";
import Link from "./Link";

function Checkout({ year }) {
  const token = useToken();
  const [url, setUrl] = useState("");
  const [calculatePreliminary, calculatePreliminaryData] =
    useTaxDeclarationsCalculatePreliminaryMutation();
  const [getPayment, paymentData] = useTaxDeclarationsPaymentsMutation();
  const [bonusChecked, setBonusChecked] = useState(false);

  const handleBonus = useCallback(() => {
    setBonusChecked((bonusChecked) => !bonusChecked);
  }, []);

  useEffect(() => {
    calculatePreliminary({ token, year, bonusChecked });
  }, [calculatePreliminary, token, year, bonusChecked]);

  useEffect(() => {
    const data = calculatePreliminaryData?.data;
    const id = data?.id;
    if (id) {
      getPayment({ token, id, bonusChecked });
    }
  }, [calculatePreliminaryData, getPayment, token]);

  useEffect(() => {
    if (paymentData?.data?.checkoutUrl) {
      setUrl(paymentData?.data?.checkoutUrl);
    }
  }, [paymentData]);

  function formatNumbers(x) {
    return x
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  let content;
  if (calculatePreliminaryData.status === "fulfilled") {
    const data = calculatePreliminaryData?.data;
    const bonus = data.availableReferralBonuses;
    content = (
      <div className="rounded-[30px] text-black bg-gradient-to-r from-cyan-650 to-cyan-75">
        <div className="px-8 py-7 text-black flex flex-col rounded-[30px] bg-white">
          <div className="pb-0 flex flex-wrap border-b border-gray-75">
            <span className="text-sm">Кількість унікальних угод</span>
            <span className="ml-auto">{data.tradesCount}</span>
            <Link
              className="relative -top-[7px] -right-[2px] h-5"
              handleClick={(e) => e.preventDefault}>
              <ImageShow image={{ url: "question", label: "question" }} />
              <div className="relative -top-4 h-[12px] justify-center opacity-0 hover:opacity-100 w-[12px]">
                <p className="absolute -top-5 text-sm border-white shadow-md min-w-[184px] ml-5 rounded-xl bg-white p-4 pointer-events-none">
                  Кількість всіх угод
                </p>
              </div>
            </Link>
          </div>
          <div className="pb-7 pt-5 flex flex-wrap border-b border-cyan-75">
            <span className="text-sm">Кількість дивідендних нарахувань</span>
            <span className="ml-auto">{data.corporateActionsCount}</span>
            <Link
              className="relative -top-[7px] -right-[2px] h-5"
              handleClick={(e) => e.preventDefault}>
              <ImageShow image={{ url: "question", label: "question" }} />
              <div className="relative -top-4 h-[12px] justify-center opacity-0 hover:opacity-100 w-[12px]">
                <p className="absolute -top-10 text-sm border-white shadow-md min-w-[184px] ml-5 rounded-xl bg-white p-4 pointer-events-none">
                  Кількість всіх корпоротивних дій
                </p>
              </div>
            </Link>
          </div>
          <div className="pt-7 pb-1 flex flex-wrap border-b border-gray-75">
            <span className="text-sm">Прибуток</span>
            <span className="ml-auto">{formatNumbers(data.profit)} грн</span>
          </div>
          <div className="pt-5 flex flex-wrap border-b border-gray-75">
            <span className="text-sm">ПДФО</span>
            <span className="ml-auto">{formatNumbers(data.pdfo)} грн</span>
          </div>
          <div className="pb-7 pt-5 flex flex-wrap border-b border-cyan-75">
            <span className="text-sm">В.З. (військовий збір)</span>
            <span className="ml-auto">
              {formatNumbers(data.militaryTax)} грн
            </span>
          </div>
          <div className="pt-6 flex text-lg flex-wrap">
            <span>Податок до сплати:</span>
            <span className="ml-auto">{formatNumbers(data.totalTax)} грн</span>
          </div>
        </div>
        <div className="p-8 text-white text-sm flex flex-col">
          <p className="text-xs mb-4">Вартість розрахунку:</p>
          <div className="pb-1 flex text-sm flex-wrap border-b border-white">
            <span>Базова вартість:</span>
            <span className="ml-auto">${formatNumbers(data.basePrice)}</span>
          </div>
          <div className="mt-3 pb-1 flex items-center text-sm flex-wrap border-b border-white">
            <span>Вартість за угоди</span>
            <span className="ml-2 text-xs">(к-ть угод*0.35$)</span>
            <span className="ml-auto">
              ${formatNumbers(data.additionalTradesPrice)}
            </span>
          </div>
          <div className="flex text-base flex-wrap mt-6 mb-1">
            <span>Реферальні бонуси: ${bonus}</span>
            <span className="ml-auto flex items-center [&>*:last-child]:flex-row-reverse [&>*:last-child>*:first-child]:p-0">
              <Checkbox
                onChange={handleBonus}
                defaultChecked={bonusChecked}
                disabled={!bonus}
                label={
                  <Typography color="white" className="text-xs pr-3">
                    використати бонуси
                  </Typography>
                }
                ripple={false}
                className="checked:border-white rounded checked:rounded checked:bg-transporent
                    h-5 w-5 border-white bg-transparent transition-all hover:before:opacity-0"
                icon={
                  <ImageShow
                    className="max-w-none relative -top-[1px]"
                    image={{ url: "checked-white", label: "checked white" }}
                  />
                }
              />
            </span>
          </div>
          <div className="flex text-lg flex-wrap">
            <span>Сума оплати розрахунку:</span>
            <span className="ml-auto">
              ${formatNumbers(data.totalValueToPay)}
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="rounded-[30px] text-black bg-gradient-to-r from-cyan-650 to-cyan-75"></div>
    );
  }

  return (
    <div className="rounded-[60px] text-white bg-gradient-to-r from-cyan-650 to-cyan-75 px-[50px] pt-[35px] pb-[75px]">
      <h5 className="ml-5 mb-5 text-lg">Попередній розрахунок:</h5>
      <div className="grid xl:grid-cols-[640px_auto] sm:gap-3 overflow-x-hidden">
        {content}
        <div className="p-3 mt-8 xl:mt-0 justify-center text-black text-sm flex flex-col items-center h-[615px] rounded-[30px] bg-white">
          {url && <Fondy url={url} />}
        </div>
      </div>
    </div>
  );
}

export default Checkout;
