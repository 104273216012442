import { useDispatch, useSelector } from "react-redux";
import UploadedAccount from "../components/UploadedAccount";
import useToken from "../hooks/use-token";
import { setAccountName, useSynchronizeFinalMutation } from "../store";
import Button from "./Button";
import Link from "./Link";

function UploadedAccounts({ synchronizingData }) {
  const dispatch = useDispatch();
  const [synchronizeFinal] = useSynchronizeFinalMutation();
  const token = useToken();
  const accountName = useSelector((state) => {
    return state.file.accountName;
  });

  const synchronizeAccount = (event) => {
    event.preventDefault();
    const data = synchronizingData?.synchronizingInvestingAccounts;
    let accounts = [];
    accounts = data.map((account) => {
      const updatedData = accountName.find(
        (item) => item.id === account.id && item.name !== account.name,
      );
      return updatedData
        ? updatedData
        : {
            id: account.id,
            name: account.name,
          };
    });
    synchronizeFinal({
      token,
      accounts,
    }).then(() => {
      dispatch(setAccountName([]));
      window.location = "/reports";
    });
  };

  let content;
  if (synchronizingData) {
    const accounts = synchronizingData?.synchronizingInvestingAccounts;
    content = accounts.map((account) => {
      return <UploadedAccount key={account.id} account={account} />;
    });
  }

  return (
    <>
      {content &&
        synchronizingData?.synchronizingInvestingAccounts.length > 0 && (
          <div className="pl-16 pr-8 mt-20">
            <h4 className="text-lg mb-5">Завантажені рахунки</h4>
            {content}
            <div className="flex">
              <Link
                to="/reports"
                handleClick={synchronizeAccount}
                className="flex justify-center items-center w-[200px] h-[60px] mb-5 justify-center border-transparent rounded-xl text-white bg-gradient-to-r from-cyan-650 to-cyan-75 hover:from-cyan-900 hover:to-cyan-650">
                Підтвердити
              </Link>
              <Button
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                }
                className="w-[200px] h-[60px] justify-center border-transparent bg-clip-text text-transparent bg-gradient-to-r from-cyan-650 to-cyan-75 hover:from-cyan-900 hover:to-cyan-650"
                // to="/accounts"
              >
                Додати файл
              </Button>
            </div>
          </div>
        )}
    </>
  );
}

export default UploadedAccounts;
