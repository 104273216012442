// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-icon {
  animation: rotate 2s linear infinite; /* Infinite rotation animation */
}

@keyframes rotate {
  100% {
    transform: rotate(360deg); /* Complete a full rotation */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/loader.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC,EAAE,gCAAgC;AACxE;;AAEA;EACE;IACE,yBAAyB,EAAE,6BAA6B;EAC1D;AACF","sourcesContent":[".loader-icon {\n  animation: rotate 2s linear infinite; /* Infinite rotation animation */\n}\n\n@keyframes rotate {\n  100% {\n    transform: rotate(360deg); /* Complete a full rotation */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
