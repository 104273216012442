import { Checkbox, Typography } from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import Button from "../components/Button";
import ImageShow from "../components/ImageShow";
import { setShowModal } from "../store";

function InvestAccountContent({ content, allowCalculation }) {
  const dispatch = useDispatch();

  const openModal = (event) => {
    event.preventDefault();
    dispatch(setShowModal(true));
  };
  return (
    <>
      <p className="text-xs text-gray-350 mt-10 mb-2">
        На основі даних рахунків буде відбуватися розрахунок
      </p>
      <div className="[&>*:not(:first-child)]:border-t [&>*:last-child]:border-b">
        {content}
      </div>

      <form onSubmit={openModal} className="hidden sm:flex mt-7 mb-5">
        <div className="mr-3 flex w-max gap-4 border-2 border-gray-75 rounded-lg pr-3 h-[60px]">
          <Checkbox
            color="blue-gray"
            required
            ripple={false}
            className="checked:border-cyan-650 rounded checked:bg-transporent w-[24px] h-[24px] hover:before:opacity-0 border-2 border-cyan-650"
            label={
              <Typography color="blue-gray" className="flex text-xs">
                Я даю згоду на&nbsp;
                <Typography
                  as="a"
                  href="/"
                  color="blue"
                  className="text-xs text-black underline-offset-2 transition-colors underline hover:no-underline">
                  обробку даних
                </Typography>
              </Typography>
            }
            icon={
              <ImageShow
                className="m-0 relative -top-[1px]"
                image={{ url: "checked-blue", label: "checked blue" }}
              />
            }
          />
        </div>
        <Button
          disabled={allowCalculation}
          type="submit"
          className="flex text-sm justify-center items-center w-[220px] h-[60px] justify-center border-transparent rounded-xl text-white bg-gradient-to-r from-cyan-650 to-cyan-75 hover:from-cyan-900 hover:to-cyan-650">
          Попередній розрахунок
        </Button>
      </form>
    </>
  );
}

export default InvestAccountContent;
