import clsx from 'clsx';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import ImageShow from '../components/ImageShow';
import useNavigation from '../hooks/use-navigation';

export function CabinetPage() {
	 const auth = useAuth();
	 const { navigate } = useNavigation();

		return (
			<div className="page">
				<h1 className="text-black text-2xl">
					Мій кабінет
				</h1>
				<div className="mt-6 mb-8 text-gray-350 text-sm inline-block flex items-center pl-4">
					<span
						className="flex justify-center items-center
												font-medium rounded-full bg-gradient-to-r from-cyan-650 to-cyan-75 w-[60px] 
												h-[60px] text-white text-[200%]"
					>
						{auth.user?.profile.given_name.slice(0, 1)}
					</span>
					<p className="ml-2 text-black font-medium text-xl">
						{auth.user?.profile.given_name} 
						<br />
						{auth.user?.profile.family_name}
					</p>
				</div>
				<div className="flex gap-2.5 wrap">
					<div className="p-7 flex flex-col gap-2 rounded-3xl bg-gray-200 w-[275px]">
							<ImageShow
								image={{ url: 'userUncolored' }}
								size={20}
								className="mb-2 w-[20px] h-[20px]"
							/>

						<span className="text-black text-xs font-medium">
							Особиста інформація
						</span>

						<button 
							className={
								clsx(
									`
										text-xs text-gray-350 w-[78px] mt-1 rounded-md py-1.5 px-2.5
										bg-gradient-to-r from-cyan-650 to-cyan-75 text-white 
										hover:bg-gradient-to-r hover:from-cyan-900 hover:to-cyan-650 
									`, 
								)
							}
							onClick={() => navigate('/profile')}
						>
							Відкрити
						</button>
					</div>
					<div className="p-7 flex flex-col gap-2 rounded-3xl bg-gray-200 w-[275px]">

						<ImageShow
							image={{ url: 'money' }}
							size={20}
							className="mb-2 w-[20px] h-[20px]"
						/>

						<span className="text-black text-xs font-medium">
							Інші доходи
						</span>

						<button 
							className={
								clsx(
									`
										text-xs text-gray-350 w-[78px] mt-1 rounded-md py-1.5 px-2.5
										bg-gradient-to-r from-cyan-650 to-cyan-75 text-white 
										hover:bg-gradient-to-r hover:from-cyan-900 hover:to-cyan-650
									`, 
								)
							}
							onClick={() => navigate('/incomes')}
						>
							Відкрити
						</button>
					</div>
				</div>
			</div>
		);
};
