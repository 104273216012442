import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_HOST } from "../../constants/constants";

const personalInfoApi = createApi({
  reducerPath: "personalInfo",
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_HOST,
  }),
  endpoints(builder) {
    return {
      // Accounts Pages
      fetchMe: builder.query({
        query: (token) => {
          return {
            method: "GET",
            url: "users/me",
            headers: {
              Authorization: `Bearer ${token.token}`,
            },
          };
        },
      }),
      updatePersonalInfo: builder.mutation({
        query: ({ token, ...body}) => {
					
          return {
            method: "PUT",
            url: "/users/personal-info",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body,
          };
        },
      }),
      updateIncomesInfo: builder.mutation({
        query: ({ token, year, ...body }) => {
          return {
            method: "PUT",
            url: `/users/other-income/${year}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body,
          };
        },
        invalidatesTags: ['other-income']
      }),
      getPersonalInfo: builder.query({
        query: (token) => {

          return {
            method: "GET",
            url: "/users/personal-info",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      getIncomesInfo: builder.query({
        query: ({ token, year }) => {
          return {
            method: "GET",
            url: `/users/other-income/${year}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        provides: ['other-income'],
      }),
		}
  },
});

export const {
	useFetchMeQuery,
	useUpdatePersonalInfoMutation,
  useGetPersonalInfoQuery,
  useUpdateIncomesInfoMutation,
  useGetIncomesInfoQuery,
} = personalInfoApi;
export { personalInfoApi };

